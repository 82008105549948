<!--

-->
<template>
  <div class=''>
    <div style="font-size: 35px">远程诊疗预约列表</div>

    <el-divider></el-divider>
<!--    根据病人姓名关键词查询预约记录-->
    <el-input
        style="width: 30%"
        placeholder="请输入关键词查询"
        v-model="search"
        clearable>
    </el-input>
<!--    预约记录，显示数据包括病人姓名（用户名）、预约时间（日期、诊疗开始时间）、症状备注（纯文本，使用按钮打开弹出框显示）。不显示的数据包括医生ID，病人ID，预约编号aID，邀请链接（不确定是否存在这里）。以上数据考虑是否拆成两组甚至更多组来存储-->
<!--    <el-table :data="appointmentData.filter(data => !search || data.appointmentUser.toLowerCase().includes(search.toLowerCase()))"
              style="width: 100%">
      <el-table-column label="用户" prop="appointmentUser"></el-table-column>
      <el-table-column label="预约时间" prop="appointmentTime"></el-table-column>

      <el-table-column label="症状备注" prop="appointmentRemark">
        <template v-slot="scope1">
          <el-popover
              title="症状备注"
              placement="right"
              trigger="click"
              width="auto">
            <div style="font-size: 15px">{{scope1.row.appointmentRemark}}</div>
            <el-button slot="reference" type="primary">查看</el-button>
          </el-popover>
        </template>
      </el-table-column>
&lt;!&ndash;      提交数据到cookie和页面跳转都写在这个按钮的功能里了，用scope获取对应行预约编号然后绑定到局部变量&ndash;&gt;
      <el-table-column label="创建诊室" prop="appointmentStart">
        <template v-slot="scope2">
          <span v-if="scope2.row.appointmentStart">&lt;!&ndash;这里直接写了一个T/F值的数据来做条件，后续要把按钮激活条件修改为是否达到预约时间&ndash;&gt;
            <el-button type="primary" v-on:click="aID=scope2.row.appointmentID" @click="remoteLink">立即创建</el-button>
          </span>
          <span v-else>
            <el-button type="primary" disabled>稍后创建</el-button>
          </span>
        </template>
      </el-table-column>
&lt;!&ndash;      取消按钮暂时没绑定功能，还需要考虑取消是直接删除记录还是使用一个T/F数据将此预约记录标记为已取消&ndash;&gt;
      <el-table-column label="取消诊疗" prop="appointmentCancel">
        <template v-slot="scope3">
          <span v-if="scope3.row.appointmentCancel">
            <el-button type="danger" >确定取消</el-button>
          </span>
          <span v-else>
            <el-button type="danger" disabled>不可取消</el-button>
          </span>
        </template>5
      </el-table-column>

    </el-table>

    <el-divider></el-divider>123456
    <div>{{appointListString}}</div>
    <div>{{typeof appointListString}}</div>
    <el-divider></el-divider>-->

    <el-table :data="appointListString.filter(data => !search || data.uname.toLowerCase().includes(search.toLowerCase()))">
      <el-table-column label="用户" prop="uname"></el-table-column>
      <el-table-column label="预约时间" prop="showTime"></el-table-column>

      <el-table-column label="预约状态" prop="state">
        <template v-slot="scope10">
          <div v-if="scope10.row.status==1" style="color: #cf9236">待接受</div>
          <div v-if="scope10.row.status==2" style="color: #4da1eb">已确认</div>
          <div v-if="scope10.row.status==3" style="color: #b30808">已拒绝</div>
        </template>
      </el-table-column>

<!--      <el-table-column label="时间改" prop="dateTime">
        <template v-slot="scope101">
        {{new Date(scope101.row.dateTime.setHours(scope101.row.dateTime.getHours()+1).replace(/-/g, '/'))}}
        </template>
      </el-table-column>-->

      <el-table-column label="病人症状描述" prop="symptoms">
        <template v-slot="scope11">
          <el-popover
              title="病人症状描述"
              placement="right"
              trigger="click"
              width="auto">
            <div style="font-size: 15px">{{scope11.row.symptoms}}</div>
            <el-button slot="reference" type="primary">查看描述</el-button>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column label="历史检查记录" prop="report">
<!--        <template v-slot="scope14">-->
<!--          <el-popover-->
<!--              title="历史报告"-->
<!--              placement="right"-->
<!--              trigger="click"-->
<!--              width="auto">-->
<!--            <div>-->
<!--              <el-table :data="ecgData0"-->
<!--                        max-height="300">-->
<!--                <el-table-column label="日期" prop="createTime"></el-table-column>-->
<!--                &lt;!&ndash;          <el-table-column label="体检类型" prop="testKind">静态单导联</el-table-column>&ndash;&gt;-->
<!--                <el-table-column label="详情" prop="testDetail">-->
<!--                  <template v-slot="scope15">-->
<!--                    <el-button @click="handleEcg(scope15.row,scope14.row)" type="primary">查看</el-button>-->
<!--                  </template>-->
<!--                </el-table-column>-->
<!--              </el-table>-->
<!--            </div>-->
<!--            <el-button slot="reference" type="primary" @click="getEcgData(scope14.row)">查看</el-button>-->
<!--          </el-popover>-->
<!--        </template>-->

        <template v-slot="scope14">
          <el-popover placement="right" trigger="click" width="auto">
            <div style="text-align: center;font-size: 20px">请选择查询项目</div>
            <el-button @click="getECG(scope14.row.userId )">
              静态心电
            </el-button>
            <el-button @click="getECGHolter(scope14.row.userId )">
              动态心电
            </el-button>
            <el-button @click="getOtherFile(scope14.row.userId )">
              其他报告
            </el-button>
            <!-- <el-button @click="get8ECGHolter(scope3.row.uid)">
              8导联
            </el-button> -->
            <el-button slot="reference" type="info">查看记录</el-button>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column label="同意/拒绝预约" prop="appointOperate">
        <template v-slot="scope15">
<!--          <el-row>-->
<!--            <el-col>-->
<!--              <span v-if="scope15.row.status==1">-->
<!--                <el-button @click="appointAccept(scope15.row)">-->
<!--              同意-->
<!--            </el-button>-->
<!--              </span><span v-else-if="scope15.row.status==2">-->
<!--              <el-button disabled>已接受</el-button>-->
<!--            </span>-->
<!--            </el-col>-->
<!--            <el-col>-->
<!--              <el-button @click="appointCancel(scope15.row)">-->
<!--                拒绝-->
<!--              </el-button>-->
<!--            </el-col>-->
<!--          </el-row>-->
          <span v-if="scope15.row.status==1">
                <el-button @click="appointAccept(scope15.row)">
              同意
            </el-button>
              </span><span v-else-if="scope15.row.status==2">
              <el-button disabled>已接受</el-button>
            </span>
          <el-button @click="appointCancel(scope15.row)">
            拒绝
          </el-button>
        </template>
      </el-table-column>

      <el-table-column label="进入诊室" prop="appointmentStart">
        <template v-slot="scope12">
<!--          <span v-if="scope12.row.status===1&&new Date() >= new Date(scope12.row.dateTime.replace(/-/g, '/'))&&new Date()<=scope12.row.endTime">-->
          <span v-if="scope12.row.status==2">
            <!--这里直接写了一个T/F值的数据来做条件，后续要把按钮激活条件修改为是否达到预约时间-->
            <el-button type="primary"  @click="click0(scope12.row)">立即进入</el-button>
          </span>
          <span v-else-if="new Date()>scope12.row.endTime">
            <el-button type="primary" disabled>已超时</el-button>
          </span>
          <span v-else>
            <el-button type="primary" disabled>稍后进入</el-button>
          </span>
        </template>
      </el-table-column>

<!--      <el-table-column label="测试" prop="remoteTest">-->
<!--        <template v-slot="scope101">-->
<!--          <el-button @click="click0(scope101.row)">测试</el-button>-->
<!--        </template>-->
<!--      </el-table-column>-->

<!--      <el-table-column label="取消诊疗" prop="appointmentCancel">-->
<!--        <template v-slot="scope13">-->
<!--          <span v-if="new Date()<scope13.row.fixTime">-->
<!--            <el-button type="danger" @click="appointCancel(scope13.row)">确定取消</el-button>-->
<!--          </span>-->
<!--          <span v-else>-->
<!--            <el-button type="danger" disabled>不可取消</el-button>-->
<!--          </span>-->
<!--        </template>-->
<!--      </el-table-column>-->

<!--      <el-table-column label="操作" prop="appointOperate">-->
<!--        <template v-slot="scope15">-->
<!--          <el-popover placement="right" trigger="click" width="auto">-->
<!--            <div style="text-align: center;font-size: 20px">预约状态调整</div>-->
<!--            <el-button @click="appointAccept(scope15.row)">-->
<!--              接受预约-->
<!--            </el-button>-->
<!--            <el-button @click="appointCancel(scope15.row)">-->
<!--              拒绝/取消预约-->
<!--            </el-button>-->
<!--            &lt;!&ndash; <el-button @click="get8ECGHolter(scope3.row.uid)">-->
<!--              8导联-->
<!--            </el-button> &ndash;&gt;-->
<!--            <el-button slot="reference" type="info">查看</el-button>-->
<!--          </el-popover>-->
<!--        </template>-->
<!--      </el-table-column>-->
    </el-table>
<!--<el-divider></el-divider>
    <el-button @click="click0()">远程</el-button>-->
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Cookies from 'js-cookie'
import Vue from "vue";
export default {
//import引入的组件需要注入到对象中才能使用

  components: {},
  data() {
//这里存放数据
    return {
      search:'',
      UID:'123',
      RID:'34350',
      aID:'',
      roomType: 'true',
      appointmentData:[{
        appointmentUser:'王芳',
        appointmentTime:'2022-7-8-11:00',
        appointmentRemark:'食物中毒',
        appointmentStart:true,
        appointmentCancel:false,
        appointmentID:'001',
      },{
        appointmentUser:'张三',
        appointmentTime:'2022-7-10-9:00',
        appointmentRemark:'崴脚',
        appointmentStart:false,
        appointmentCancel:true,
        appointmentID:'002',
      },],

      appointListString:[],
      appointListString1:[],
      appointListString2:[],
      reshapedList:[],
      ecgData0: [],
    };
  },
//监听属性 类似于data概念
  computed: {
    crossPlatformStatus: {
      get() { return this.$store.state.module.crossPlatform },
      set(val) { this.$store.commit('module/updateCrossPlatform', val) }
    }
  },
//监控data中的数据变化
  watch: {},
//方法集合
  methods: {
    appointAccept(e){
      console.log(e.orderId)
      this.$http({
        url: this.$http.adornUrl('/doctor/recordorder/updateStatus'),
        method: 'GET',
        params: {
          orderId: e.orderId,
          statusType: 2,
        }
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看提交后返回的东西")
          console.log(data)
          this.$notify({
            title: '成功',
            message: '提交成功',
            type: 'success'
          })
          window.location.reload()
        } else {
          this.$message.error(data.msg)
        }
      })

    },
    click0(arr){
      Cookies.set('UID',this.$store.state.doctor_station.docid,{path:"/"})
      Cookies.set('RID',arr.orderId,{path:"/"})
      Cookies.set('aID',arr.orderId,{path:"/"})
      Cookies.set('PID',arr.userId,{path:"/"})
      Cookies.set('PType',arr.userType,{path:"/"})
      Cookies.set('PName',arr.uname,{path:"/"})
      Cookies.set('Symptoms',arr.symptoms,{path:"/"})
      Cookies.set('aTime',arr.dateTime,{path:"/"})
      Cookies.set('RType',this.roomType,{path:"/"})
      Cookies.set('Dname',this.$store.state.doctor_station.doctorName,{path:"/"})
      const routeData = this.$router.resolve({
        name: 'docTreat',
        query: {
          "UID": this.$store.state.doctor_station.docid,
          "RID": arr.orderId,
          "aID": arr.orderId,
          "PID": arr.userId,
          "PType": arr.userType,
          "PName": arr.uname,
          "Symptoms":arr.symptoms,
          "aTime": arr.dateTime,
          "RType": this.roomType,
        },
      });

      // window.open(routeData.href,'_blink');

      this.$router.push({
        name: 'docTreat1',
        // query: {
        //   "staticRecordId": e.sid,
        //   "staticRecordCreatetime": e.createTime,
        //   "staticRecordDuration": e.duration, ................................0
        //   "staticRecordPID": f.userId,
        //   "RType":this.roomType,
        // }
        query: {
          "UID": this.$store.state.doctor_station.docid.toString(),
          "RID": arr.orderId,
          "aID": arr.orderId,
          "PID": arr.userId,
          "PType": arr.userType,
          "PName": arr.uname,
          "Symptoms":arr.symptoms,
          "aTime": arr.dateTime,
          "RType": this.roomType,
          "UserId": arr.userId,
        },
      })
      console.log("CanCanNeed")
      console.log(this.$store.state.doctor_station.docid,arr.orderId)
    },

    remoteLink(arr){
      /* 本地使用cookie在同域名下携带数据*/
      Cookies.set('UID',this.$store.state.doctor_station.docid,{path:"/"})
      Cookies.set('RID',arr.orderId,{path:"/"})
      Cookies.set('aID',arr.orderId,{path:"/"})
      Cookies.set('PID',arr.userId,{path:"/"})
      Cookies.set('PType',arr.userType,{path:"/"})
      Cookies.set('PName',arr.uname,{path:"/"})
      Cookies.set('Symptoms',arr.symptoms,{path:"/"})
      Cookies.set('aTime',arr.dateTime,{path:"/"})
      Cookies.set('RType',this.roomType,{path:"/"})

      // window.open("https://www.xin-gou.com/remote",'_blank')

      this.$router.push({
        name: 'docTreat',
        // query: {
        //   "staticRecordId": e.sid,
        //   "staticRecordCreatetime": e.createTime,
        //   "staticRecordDuration": e.duration,
        //   "staticRecordPID": f.userId,
        //   "RType":this.roomType,
        // }
      })
    },

    getECG(e){
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({ name: 'HistorySingoJump',
        query: {
          uid: uid,
        } })
    },
    getECGHolter(e){
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({ name: 'HistorySingoHolterJump',
        query: {
          uid: uid,
        } })
    },
    getOtherFile(e){
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({ name: 'HistoryPhotoJump',
        query: {
          uid: uid,
        } })
    },

    appointmentGet(){
      console.log("看看有没有DocID")
      console.log(this.$store.state.doctor_station.docid)
      this.$http({
        url: this.$http.adornUrl('/doctor/recordorder/listById'),
        method: 'get',
        params: this.$http.adornParams({ docId: this.$store.state.doctor_station.docid})
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看收到的数据")
          console.log(data)
          this.appointListString = data.recordOrderList
          Vue.set(this.appointListString,'endTime','')
          for (var I of this.appointListString) {
            /*let aTime= new Date(I.dateTime.replace(/-/g, '/'))*/
            let aTime= I.dateTime.replace(/-/g, '/')
            let bTime= new Date(aTime)
            bTime.setHours(bTime.getHours()+1)
            let cTime= bTime
            I.endTime=cTime
          }
          Vue.set(this.appointListString,'fixTime','')
          for (var t of this.appointListString) {
            let xTime= t.dateTime.replace(/-/g, '/')
            let yTime= new Date(xTime)
            yTime.setDate(yTime.getDate()-1)
            let zTime= yTime
            t.fixTime= zTime
          }
          Vue.set(this.appointListString,'showTime','')
          for (var it of this.appointListString) {
            let xTime= it.dateTime.replace(/-/g, '/')
            let yTime= new Date(xTime)
            yTime.setDate(yTime.getDate())
            let zTime= yTime
            let nowDay = zTime.getDate();
            let nowMonth = zTime.getMonth()+1;
            let nowYear = zTime.getFullYear();
            if(zTime.getHours()==12){
              it.showTime= nowYear+"-"+nowMonth+"-"+nowDay+"上午"
            }
            if(zTime.getHours()==18){
              it.showTime= nowYear+"-"+nowMonth+"-"+nowDay+"下午"
            }
          }
          /*this.appointListString1 = JSON.stringify(this.appointListString)
          this.appointListString1 = this.appointListString1.slice(1)
          this.appointListString1 = this.appointListString1.slice(0,this.appointListString1.length-1)
          this.appointListString2 = JSON.parse(this.appointListString1)

          this.reshapedList.push(this.appointListString2)*/

        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
    },

    appointCancel(arr) {
      this.$http({
        url: this.$http.adornUrl('/doctor/recordorder/cancelOrder'),
        method: 'get',
        params: this.$http.adornParams({orderId: arr.orderId})
      }).then(({data}) => {
        if (data && data.code === 0) {
          console.log("看看反馈的数据")
          console.log(data)
          this.$notify({
            title: '成功',
            message: '取消成功',
            type: 'success'
          })
          window.location.reload()
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
      // setTimeout(location. reload(),100);

    },

    getEcgData(arr1) {
      this.$http({
        url: this.$http.adornUrl("/doctor/personalInfo/staticecg/list"),
        method: "get",
        params: this.$http.adornParams({ uid: arr1.userId }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看静态单导列表");
          console.log(data)
          this.ecgData0 = data.staticEcgEntities;
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg);
        }
      });

    },

    handleEcg(e,f) {
      console.log(e,f);
      // this.staticRecordId = e.sid
      this.$router.push({
        name: 'MainHistorySingo2',
        query: {
          "staticRecordId": e.sid,
          "staticRecordCreatetime": e.createTime,
          "staticRecordDuration": e.duration,
          "staticRecordPID": f.userId,
          "RType":this.roomType,
        }
      })
      // this.$router.replace({ name: 'HistorySingo2' })
    },
    /*linkget(){
      this.$http({
        url: this.$http.adornUrl('/doctor/recordorder/createLink'),
        method: 'get',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看收到的链接")
          console.log(data)
          /!*this.appointListString1 = JSON.stringify(this.appointListString)
          this.appointListString1 = this.appointListString1.slice(1)
          this.appointListString1 = this.appointListString1.slice(0,this.appointListString1.length-1)
          this.appointListString2 = JSON.parse(this.appointListString1)

          this.reshapedList.push(this.appointListString2)*!/

        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
    }*/
  },
//生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.appointmentGet()
    /*this.linkget()*/
  },
//生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>

</style>
